import type { App } from "vue"
import { createPinia } from "pinia"
import { defineStore } from "pinia"
import { Names } from "./store-name" //导入枚举的名字

const store = createPinia()

export function setupStore(app: App<Element>) {
  app.use(store)
}

export const useLocaleStore = defineStore(Names.LOCALE, {
  state: () => {
    return {
      locale: 'zh-CN', //默认语言为中文
    }
  }
})
export const useProIndexStore = defineStore(Names.PROINDEX, {
  state: () => {
    return {
      proIndex: '/', //默认产品导航栏的默认值
    }
  }
})