import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { createI18n } from "vue-i18n"
import { createPinia } from "pinia"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import "animate.css"
import "wowjs/css/libs/animate.css"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import * as echarts from "echarts"
import VueLazyload from 'vue-lazyload'
import "@/assets/text/font.css";
const messages = {
  en: require("../locales/en.json"),
  "zh-CN": require("../locales/zh-CN.json"),
}

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(VueLazyload)
const i18n = createI18n({
  legacy: false, // Vue 3.x 版本需要设置此选项为 false
  locale: sessionStorage.getItem('locale') === 'en' ? 'en' : 'zh-CN', // 默认语言为中文
  messages,
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(i18n)
app.use(router).use(ElementPlus).mount("#app")
// app.use(ElementPlus, {
//   locale: store.locale === 'zh-CN' ? zhCn : 'en',
// })


app.config.globalProperties.$echarts = echarts
