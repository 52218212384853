import api from "./http"

// 获取目录列表
export const getMenuData = () =>
  api({
    url: `/ram/product/product_menu/get/`,
    method: "get"
  })
export const getProductData = (index) =>
  api({
    url: `/ram/product/product_content/get/?index=${index}`,
    method: "get"
  })
export const getProductNav = () =>
  api({
    url: `/ram/product/second_menu/get/`,
    method: "get"
  })
export const submitMessage = (data) =>
  api({
    url: `/ram/product/submit_message/`,
    method: "post",
    data
  })
