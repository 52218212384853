import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from "@/views/Home/HomeView.vue"
import AboutView from "@/views/About/AboutView.vue"
import Contact from "@/views/Contact/ServiceSupport/index.vue"
import RDTeam from "@/views/About/TeamView.vue"
import LogoView from "@/views/About/LogoView.vue"
import TechView from "@/views/Technology/TechView.vue"
import ServiceSupport from "@/views/Contact/ServiceSupport/index.vue"
import ProductPipeline from "@/views/Product/ProductPipeline/index.vue"
import ProductDetails from "@/views/Product/ProductDetails/index.vue"
import RecruitView from "@/views/Contact/recruit/index.vue"
import ContactUs from "@/views/Contact/contactus/index.vue"
import { getProductNav } from '@/api/product'
import { ref } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/product",
    name: "product",
    component: ProductPipeline,
  },
  {
    path: "/productdetails",
    name: "productDetails",
    component: ProductDetails,
    
  },
  {
    path: "/talent",
    name: "talent",
    component: ServiceSupport,
  },
  //, {
  //   path: '/about/profile',
  //   name: 'profile',
  //   component: ComProfile
  // }, {
  //   path: '/about/history',
  //   name: 'history',
  //   component: DevHistory
  // }, {
  //   path: '/about/honor',
  //   name: 'honor',
  //   component: ComHonor
  // }, 
  {
    path: '/about/rdteam',
    name: 'rdteam',
    component: RDTeam
  }
  , {
    path: '/about/logo',
    name: 'logo',
    component: LogoView
  },
  , {
    path: '/tech',
    name: 'tech',
    component: TechView
  }
  , {
    path: '/recruit',
    name: 'recruit',
    component: RecruitView
  }, {
    path: '/contact',
    name: 'contact',
    component: ContactUs
  }
]

const proNav = ref([]);

// 异步获取产品导航数据并添加到路由
async function setupRoutes() {
    const res = await getProductNav();
    proNav.value = res.data;
    proNav.value.forEach((item: any) => {
      routes.push({
        path: `/product/${item.nav_index}`,
        name: item.title,
        component: ProductDetails,
      });
    });
}

// 等待动态路由设置完成
await setupRoutes();

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
