<template>
  <div class="bottom-box">
    <div class="top">
      <div class="left">
        <div class="logo-img">
          <img v-lazy="require('@/assets/LOGO-white.png')" alt="">
        </div>
      </div>
      <div class="right">
        <div class="address">
          <el-icon size="13" style="color: #fff">
            <LocationFilled />
          </el-icon> <span>地址：厦门市集美区灌口镇集美北大道安仁产业园1068-17号7楼</span>
        </div>
        <div class="address"> <el-icon size="13" style="color: #fff">
            <Promotion />
          </el-icon><span> 网址：https://rekeymed.com</span></div>
      </div>
      <div class="right">
        <div class="address"><el-icon size="13" style="color: #fff">
            <PhoneFilled />
          </el-icon> <span> 电话：0592-7272801</span>
        </div>
        <div class="address"><span class="iconfont">&#xe602;</span><span> 邮箱：contact@rekeymed.com
        </span>
        </div>
      </div>
      <div class="code-img">
        <img src="@/assets/code.jpg" alt="">
        <div>扫一扫关注我们</div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="privacy">
        <a>网站备案：xxxxxxx|网站声明与隐私政策</a>
      </div> -->
      <div class="privacy">
        <a href="https://ythzxfw.miit.gov.cn/index" target="blank">版权所有 © 厦门瑞聚医学科技有限公司</a>
      </div>
      <!-- <div class="wechat">
        <span>微信公众号</span>
        <div class="img">
          <img v-lazy="require('@/assets/wechat.png')" alt="">

        </div>
      </div> -->
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'iconfont';
  src: url('@/assets/icon/iconfont.ttf?t=1690168917555') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 13px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.bottom-box {
  height: 110px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 15px 0;
  background-color: #0058af;
  // background: linear-gradient(#b2c9f3 0%, #97b7f1 100%);
  justify-content: space-around;
  font-size: 10px;
  color: #ffffff;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .left {
      // align-items: ;
      justify-content: space-around;

      .logo-img {
        width: 200px;

        img {
          width: 100%;
          // height: 100%;
        }
      }
    }

    .right {

      .address {
        margin: 5px 0;
      }
    }


  }

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;

    .privacy {
      a {
        text-decoration: none;
        color: #fff;
      }
    }

    .wechat {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      align-items: flex-end;

      .img {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 3px;
        width: 20px;
        height: auto;

        img {
          width: 100%;
        }
      }
    }
  }
}

.code-img {
  width: 100px;
  height: 100px;
  display:flex;
  flex-direction:column;
align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .bottom-box {
    display: flex;
    flex-direction: column;
    height: auto;

    .top {
      display: flex;
      flex-direction: column;
    }

    .privacy {
      margin: 3px 0;
    }

    .wechat {
      display: flex;
      align-items: center !important;
    }

    .img {
      margin-left: 3px;
      margin: 5px 0;
      width: 30px;
      height: auto;

      img {
        width: 100%;
      }
    }
  }

}
</style>