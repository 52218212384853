import axios from "axios"

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL as string,
  timeout: 3000 * 100 // 请求超时时间 当请求时间超过5秒还未取得结果时 提示用户请求超时
})

// const user = JSON.parse(sessionStorage.getItem("user"))
// if (user) {
//   console.log("http",user);
  
//   api.defaults.headers.common["Authorization"] = "Bearer " + user.access_token
// }

// interceptors axios的拦截器对象
api.interceptors.request.use(
  config => {
    // config 请求的信息
    return config // 将配置完成的config对象返回出去 如果不返回 请求则不会进行
  },
  err => {
    // 请求发生错误时的处理 抛出错误
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  res => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息
    return Promise.resolve(res)
  },
  err => {
    // 服务器响应发生错误时的处理
    return Promise.reject(err)
  }
)

export default api
