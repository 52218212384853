import { useRouter } from 'vue-router'
import { onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import {getProductNav} from '@/api/product'
import {useProIndexStore} from '@/store/index'
export function useNav() {
    const router = useRouter()
    const langs = [
        { show: '中', locale: 'zh-CN' },
        { show: 'en', locale: 'en' }
      ]
      
      const isCollapse = ref(false)
      const ellipsis = ref(false)
      const navWidth = ref(window.innerWidth) // 导航栏的宽度，初始值为当前屏幕宽度
      const activeIndex = ref('/')
      const productNav = ref([])
    const locale = ref(sessionStorage.getItem('locale') || 'zh-CN')
    const changeLang = (e) => {
      console.log(e)
      const currentUrl = window.location.href
      // 通过改变 URL 参数来触发页面刷新
      window.location.href = currentUrl
      sessionStorage.setItem('locale', e)
    }
    const changeHref = (e) => {
      sessionStorage.setItem('activeIndex', e)
    }
    // 添加窗口大小变化时的监听器，实时更新导航栏宽度
    const updateNavWidth = () => {
      navWidth.value = window.innerWidth
      isCollapse.value = window.innerWidth < 800
      ellipsis.value = window.innerWidth < 1250
    }
    
    // 添加窗口大小变化时的监听器，实时更新折叠状态
    const updateCollapse = () => {
      navWidth.value = window.innerWidth
      isCollapse.value = window.innerWidth < 800
      ellipsis.value = window.innerWidth < 1250
    }
    // 添加窗口大小变化时的监听器，实时更新导航栏宽度
    window.addEventListener('resize', updateNavWidth)
    onMounted(() => {
      getProductNav().then(res => {
        productNav.value = res.data
      })
      // 在组件挂载时调用方法
      activeIndex.value = sessionStorage.getItem('activeIndex') || '/'
      locale.value = sessionStorage.getItem('locale') || 'zh-CN'
      if(activeIndex.value.includes('product')){
      useProIndexStore().proIndex = sessionStorage.getItem('activeIndex').split('/')[2]
      }
      // 添加窗口大小变化时的监听器，实时更新折叠状态
      window.addEventListener('resize', updateCollapse)
      // 更新导航栏宽度
      updateNavWidth()
    })
    
    onBeforeUnmount(() => {
      // 组件销毁前，移除监听器
      window.removeEventListener('resize', updateCollapse)
    })
    const handleSelect = (key: string, keyPath: string[]) => {
        sessionStorage.setItem('activeIndex', key)
        router.push(key)
      
      }
    const SelectSubItem = (item) => {
    useProIndexStore().proIndex = item.nav_index
    }
return{
    handleSelect,
    SelectSubItem,
    activeIndex,
    isCollapse,
    ellipsis,
    navWidth,
    locale,
    changeLang,
    changeHref,
    langs,
    productNav,
}
}